import { Chip } from '@quintype/em/components/chip';
import { FieldLabel } from '@quintype/em/components/field-label';
import { Switch } from '@quintype/em/components/switch';
import { TextFieldWithButton } from '@quintype/em/components/text-field';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { otherConfig, updateHaveUnsavedChanges, updateSpaConfig } from '../../actions/builder-action';
import CloseIcon from '../../atoms/close-icon';
import styles from './others.module.css';

export const Others = () => {
  const dispatch = useDispatch();
  const [domain, setInputDomain] = useState('');
  const [urlParams, setUrlParams] = useState('');
  const { enableNewsArticle = true, whiteListedDomains = [], whiteListedUrlParams = [] } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'others'], {})
  );
  const { disableCache = false } = useSelector(state =>
    get(state, ['builder', 'config', 'general', 'serviceWorker'], {})
  );

  const handleUrlParams = ({
    paramsArray,
    paramToRemove,
    configKey
  }: {
    paramsArray: string[];
    paramToRemove?: string;
    configKey: string;
  }) => {
    if (paramToRemove) {
      const positionOfItem = paramsArray.indexOf(paramToRemove);
      paramsArray.splice(positionOfItem, 1);
    } else {
      paramsArray.map((item: string) => item.trim()).filter(Boolean);
    }
    dispatch(otherConfig({ [configKey]: paramsArray }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  return (
    <div className={styles.wrapper}>
      <>
        <div className={styles.toggleWrapper}>
          <FieldLabel label="Progressive Web App" />
          <Switch
            id="spa"
            onChange={(checked: boolean) => {
              dispatch(updateSpaConfig({ disableCache: !checked }));
              dispatch(updateHaveUnsavedChanges(true));
            }}
            checked={!disableCache}
          />
        </div>
      </>
      <>
        <FieldLabel label="Website Schema" />
        <div className={styles.switchWrapper}>
          <div className={styles.content}>News Articles</div>
          <Switch
            id="newsArticles"
            onChange={(checked: boolean) => {
              dispatch(otherConfig({ enableNewsArticle: checked }));
              dispatch(updateHaveUnsavedChanges(true));
            }}
            checked={enableNewsArticle}
          />
        </div>
      </>
      <>
        <FieldLabel label="CSP Frame-Ancestors" />
        <div className={styles.description}>
          This sets frame-ancestors directive in Content Security Policy header so that your site's content can't be
          maliciously added in an iframe.
        </div>
        <TextFieldWithButton
          label="Whitelist Domains"
          buttonLabel="Add"
          value={domain}
          readOnly={false}
          onChange={(value: string) => setInputDomain(value)}
          classname="textFieldCustomStyle"
          onButtonClick={() => {
            dispatch(otherConfig({ whiteListedDomains: [...whiteListedDomains, domain] }));
            dispatch(updateHaveUnsavedChanges(true));
            setInputDomain('');
          }}
        />
        <div className={styles.domainsList}>
          {whiteListedDomains.map((domainItem: string, index: number) => (
            <Chip
              classname="chipCustomStyle"
              value={domainItem}
              key={index}
              placeIconRight={() => (
                <CloseIcon
                  className="closeIconWrapper"
                  onCloseClick={() => {
                    dispatch(otherConfig({ deletedDomain: domainItem }));
                    dispatch(updateHaveUnsavedChanges(true));
                  }}
                />
              )}
            />
          ))}
        </div>
      </>
      <>
        <FieldLabel label="Add URL Parameters" />
        <div className={styles.description}>
          Enter query parameters to customise your website's URL(s). Use key-value pairs to track campaigns or pass
          additional data.
        </div>
        <TextFieldWithButton
          label="Enter URL Parameters"
          buttonLabel="Add"
          value={urlParams}
          readOnly={false}
          onChange={(value: string) => setUrlParams(value)}
          classname="textFieldCustomStyle"
          onButtonClick={() => {
            handleUrlParams({ paramsArray: [...whiteListedUrlParams, urlParams], configKey: 'whiteListedUrlParams' });
            setUrlParams('');
          }}
        />
        <div className={styles.domainsList}>
          {whiteListedUrlParams.map((param: string, index: number) => (
            <Chip
              classname="chipCustomStyle"
              value={param}
              key={index}
              placeIconRight={() => (
                <CloseIcon
                  className="closeIconWrapper"
                  onCloseClick={() =>
                    handleUrlParams({
                      paramToRemove: param,
                      paramsArray: [...whiteListedUrlParams],
                      configKey: 'whiteListedUrlParams'
                    })
                  }
                />
              )}
            />
          ))}
        </div>
      </>
    </div>
  );
};
