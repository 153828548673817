import edgeIcon from './svgs/edge.svg';
import curveIcon from './svgs/curve.svg';
import {
  BlockQuoteTemplateList,
  BlurbTemplateList,
  QuoteTemplateList,
  SummaryTemplateList,
  AlsoReadTemplateList,
  QuestionAnswerTemplateList,
  QuestionTemplateList,
  AnswerTemplateList
} from './template-list';

export const storyElementsList = [
  {
    title: 'Text',
    description:
      'Text story element displays the text element of a story with links styled to be of a different color than the rest of the text and have underline effect on hover',
    key: 'text',
    componentName: 'Text',
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Text Color',
          key: 'textColor'
        },
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Hyperlink Color',
          key: 'hyperlinkColor'
        }
      ]
    }
  },
  {
    title: 'Block Quote',
    description:
      'A Blockquote is a quote with a semantic twist. It typically refers to a `block` of text, which is often `quoted` from an existing source of work. It is sometimes appended with an attribute to cite the source of quotation.',
    key: 'blockquote',
    componentName: 'BlockQuote',
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Block Quote Background Color',
          key: 'backgroundShade'
        },
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Block Quote Icon Color',
          key: 'blockQuoteColor'
        },
        {
          type: 'selectDesign',
          settingsType: 'css',
          label: 'Choose icon style',
          key: 'iconType',
          options: [
            { name: 'curveIcon', svg: curveIcon },
            { name: 'edgeIcon', svg: edgeIcon }
          ]
        }
      ]
    },
    templatesList: BlockQuoteTemplateList
  },
  {
    title: 'Quote',
    description:
      'A Quote is used to cite pieces of text which are often phrases from articles or even reviews that you would like to highlight. They are used to increase the credibility of your stories and to engage your audience with legitimate references.',
    key: 'quote',
    componentName: 'Quote',

    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Quote Border Color',
          key: 'borderColor'
        }
      ]
    },
    templatesList: QuoteTemplateList
  },
  {
    title: 'Blurb',
    description:
      'A Blurb is a row or a grid of text used to display pieces of important information that you would like to throw attention to in an article. They often work as an excerpt of text and are also used to highlight parts of text.',
    key: 'blurb',
    componentName: 'Blurb',
    templatesList: BlurbTemplateList,

    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Blurb Border Color',
          key: 'borderColor'
        }
      ]
    }
  },
  {
    title: 'Summary',
    description:
      'A Summary is a story element that is used to give readers a brief of the article. It is usually placed at the beginning of an article and serves as an introduction.',
    key: 'summary',
    componentName: 'Summary',

    templatesList: SummaryTemplateList,

    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'header BG Color',
          key: 'headerBgColor'
        }
      ],
      opts: [
        {
          type: 'text',
          settingsType: 'opts',
          label: 'Enter Title',
          key: 'headline'
        },
        {
          type: 'checkbox',
          settingsType: 'opts',
          label: 'Disable Title Text',
          key: 'hideHeadline'
        }
      ]
    }
  },
  {
    title: 'Also Read',
    description:
      'The Also Read element is used to display the summary of an article and the link to it. This element works as an introduction to a related story that your reader may want to explore.',
    key: 'also-read',
    componentName: 'AlsoRead',
    templatesList: AlsoReadTemplateList,
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Choose colour',
          key: 'textColor'
        }
      ],
      opts: [
        {
          type: 'text',
          settingsType: 'opts',
          label: 'Also Read title',
          key: 'title'
        }
      ]
    }
  },
  {
    title: 'Big Fact',
    description:
      'A Big Fact story element is often used to stress on facts within the article, that publishers want to bring reader attention to. Big facts are often appended with short text to describe the fact in little more detail.',
    key: 'bigfact',
    componentName: 'BigFact'
  },
  {
    title: 'Attachment',
    description: 'The Attachment element enables users to embed files or documents in the story.',
    key: 'attachment',
    componentName: 'Attachment',
    settings: {
      opts: [
        {
          type: 'text',
          settingsType: 'opts',
          label: 'Title',
          key: 'attachmentTxt',
          placeholder: 'Attachment'
        },
        {
          type: 'text',
          settingsType: 'opts',
          label: 'Preview Button',
          key: 'previewTxt',
          placeholder: 'Preview'
        }
      ]
    }
  },
  {
    title: 'Image',
    description:
      'Any Image on you website is sized to fit your story specifications, and is made responsive across devices.',
    key: 'image',
    componentName: 'Image'
  },
  {
    title: 'Reference',
    description:
      'The Reference element is used to display a reference link along with a name and description. Similar to ‘Also Read’, this element works as an introduction to a related story that your reader may want to explore.',
    key: 'references',
    componentName: 'Reference',
    settings: {
      opts: [
        {
          type: 'checkbox',
          settingsType: 'opts',
          label: 'Enable Title Text',
          key: 'showHeadline'
        },
        {
          type: 'text',
          settingsType: 'opts',
          label: 'Title Text',
          key: 'headlineText'
        }
      ]
    }
  },
  {
    title: 'Question and Answer',
    description:
      "Questions and Answers, is a communication format where one party poses inquiries while another party provides corresponding responses. It's widely used to exchange information, clarify concepts, and foster interactive discussions.",
    key: 'q-and-a',
    componentName: 'QuestionAnswer',
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Q&A Icon Background Color',
          key: 'iconColor'
        }
      ]
    },
    templatesList: QuestionAnswerTemplateList
  },
  {
    title: 'Question',
    description:
      'A question is a linguistic expression or inquiry presented by one party in a communication exchange, seeking information, clarification, or a response from another party. Questions are typically formulated to prompt a specific kind of answer or to gather details, opinions, or knowledge about a particular topic.',
    key: 'question',
    componentName: 'QuestionAnswer',
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Question Icon Background Color',
          key: 'iconColor'
        }
      ]
    },
    templatesList: QuestionTemplateList
  },
  {
    title: 'Answer',
    description:
      'An answer refers to a verbal or written response provided by one party in the exchange, typically in response to a question posed by another party. An answer is intended to address the specific inquiry, provide information, offer insights, or convey a perspective related to the subject of the question.',
    key: 'answer',
    componentName: 'QuestionAnswer',
    settings: {
      css: [
        {
          type: 'colorPicker',
          settingsType: 'css',
          label: 'Answer Icon Background Color',
          key: 'iconColor'
        }
      ]
    },
    templatesList: AnswerTemplateList
  }
];
